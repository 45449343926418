/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { Image } from '../ui/image';
import { ImageGallery } from '../ui/imageGallery';
import images from '../../utils/store/images';
import Button from '../ui/button';
import Divider from '../ui/divider';
import pages from '../../utils/pages';

const { getPageUrl } = pages;
const ConversionSection: FC<{
  // eslint-disable-next-line react/require-default-props
  ctaUrl?: any;
}> = ({ ctaUrl }) => {
  const { t } = useTranslation('general');

  const leftList: any = t('conversion.leftList', {
    returnObjects: true,
  });
  const rightList: any = t('conversion.rightList', {
    returnObjects: true,
  });
  const { shoreSalesPeople } = images;

  return (
    <section className=" section-conversion" id="sales">
      <div className="container">
        <div className="columns is-centered  ">
          <div className="column is-8 is-centered">
            <h2 className="title title-large has-text-centered has-text-white">
              {t('conversion.title')}
            </h2>
            <p className=" has-text-centered has-text-white">
              {t('conversion.text')}
            </p>
          </div>
        </div>
        <div className="columns is-centered has-text-centered">
          <div className="column is-10-desktop  has-background-white conversion-wrapper is-centered">
            <div className="columns">
              {!isMobileOnly && (
                <div className="column web-demo">
                  <h3 className="title title-medium   text-black">
                    {t('conversion.titleLeft')}
                  </h3>
                  <ul>
                    {Object.keys(leftList).map((key) => {
                      return (
                        <li key={key}>
                          <Image
                            name="check_black.svg"
                            className="inline-svg inline-icon"
                            alt="shore.com"
                          />
                          {leftList[key]}
                        </li>
                      );
                    })}
                  </ul>
                  <Button
                    size="inline"
                    to={getPageUrl('demo', t('key')).url}
                    color="exclusive"
                  >
                    {t('footerDemoButton')}
                  </Button>
                  <div className="image-container image-list">
                    {Object.keys(shoreSalesPeople).map((key: any) => {
                      return (
                          <ImageGallery
                            name={shoreSalesPeople[key]}
                            alt="we support you"
                            className="sales_person"
                            maxWidth="70"
                            height="70"
                            key={key}
                          />
                      );
                    })}
                  </div>
                </div>
              )}
              <Divider />

              <div className="column web-demo ">
                <h3 className="title title-medium  text-black">
                  {t('conversion.titleRight')}
                </h3>
                <ul>
                  {Object.keys(rightList).map((key) => {
                    return (
                      <li key={key}>
                        <Image
                          name="check_black.svg"
                          className="inline-icon inline-svg"
                          alt="shore.com"
                        />
                        {rightList[key]}
                      </li>
                    );
                  })}
                </ul>
                <div className="demo_form">
                  <form
                    id="ssuformHeader"
                    className="ssuform"
                    method="post"
                    action="<?php echo lang('tryForFree');?>"
                  >
                    <div className="field ">
                      <p className="control">
                        <Button
                          size="inline"
                          to={
                            getPageUrl(ctaUrl, t('key'))
                              ? getPageUrl(ctaUrl, t('key')).url
                              : getPageUrl('ssu', t('key')).url
                          }
                          primary
                        >
                          {t('demo_long')}
                        </Button>
                      </p>
                    </div>
                  </form>
                </div>
               
              </div>
              {isMobileOnly && (
                <>
                  <hr />
                  <div className="column web-demo">
                    <h3 className="title title-medium   text-black">
                      {t('conversion.titleLeft')}
                    </h3>
                    <ul>
                      {Object.keys(leftList).map((key) => {
                        return (
                          <li key={key}>
                            <Image
                              name="check_black.svg"
                              className="inline-svg inline-icon"
                              alt="shore.com"
                            />
                            {leftList[key]}
                          </li>
                        );
                      })}
                    </ul>
                    <Button
                      size="inline"
                      to={getPageUrl('demo', t('key')).url}
                      color="exclusive"
                    >
                      {t('footerDemoButton')}
                    </Button>
                    <div className="image-container image-list">
                      {Object.keys(shoreSalesPeople).map((key: any) => {
                        const image = shoreSalesPeople[key];
                        return (
                          <div key={key}>
                            <ImageGallery
                              name={image}
                              alt="we support you"
                              className="sales_person"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConversionSection;

import * as React from 'react';
import styled from 'styled-components';

const VerDivider = styled.span`
  padding: 0;
  display: block;
  flex: none;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  position: relative;
  border-top: none;
  min-height: 2rem;
  &:before {
    content: '';
    border-left: 1px solid #dbdbdb;
    bottom: 0;
    top: 0;
    display: block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
`;

function Divider() {
  return <VerDivider className="is-hidden-mobile" />;
}

export default Divider;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState,useEffect, FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { LocalizedPageProps } from '../utils/types';

import ComparisonSection from '../components/partials/products/section-comparison';
 
import QuoteSlider from '../components/sections/quote';
import ConversionSection from '../components/sections/conversion';
import { ImageGallery } from '../components/ui/imageGallery';
import { Image } from '../components/ui/image';
import Animation from '../components/ui/lottie';
import AOS from 'aos';
 

import 'aos/dist/aos.css'; // You can also use <link> for styles
 

import Button from '../components/ui/button';
import pages from '../utils/pages';
 
 import {
  KollektionSlider,
  FullWidthFlipCards,
  FullWidthtexCards,
 
 
} from '../components/partials/common/elements/Slider';

const AboutUsPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('why-shore');
  const { lang, alternateUrls } = pageContext;
 
  useEffect(() => {
    AOS.init(
     { 
      duration: 2000,

     }
    );

 
  });
  const hero: any = t('hero', {
    returnObjects: true,
  });

  const sections: any = t('sections', {
    returnObjects: true,
  });
  const roi: any = t('roi', {
    returnObjects: true,
  });
  const comparison: any = t('comparison', {
    returnObjects: true,
  });

  const collection: any = t('collection', {
    returnObjects: true,
  });

  const start: any = t('start', {
    returnObjects: true,
  });

  const quote: any = t('quote', {
    returnObjects: true,
  });
  const { getPageUrl } = pages;
  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <section className="hero text-hero mb-0 pb-0">
        <div className="hero-body is-vcentered">
          <div className="container">
            <div className="column is-12-touch is-10 centered_hero_content p-0">
              <h1
                className="title title-big text-black is-hidden-mobile p-0"
                dangerouslySetInnerHTML={{ __html: hero.title }}
              />
               <h1
                className="title title-big text-black is-hidden-desktop"
                dangerouslySetInnerHTML={{ __html: hero.title_mobile }}
              />
            </div>
          </div>
        </div>
      </section>
       {Object.keys(sections).map((key, i) => {
        const [showCards, setShowCards] = useState(true); //
        const { image, title, text, cards, image_mobile } = sections[key];
        return (
          <section
          data-aos="fade-up"
            className={`${i} section why_section`}
            id={` why_section_${i}`}
          >
            <div className="container ">
              <ImageGallery
                name={image}
                alt=""
                className="section-image image-centered is-hidden-mobile "
                maxWidth="1258"
                height="458"
              />
              <ImageGallery
                name={image_mobile}
                alt=""
                className="section-image image-centered is-hidden-desktop "
                maxWidth="415"
                height="458"
              />
            </div>
            <div className="container  pt-5">
              <h2 className="title title-medium section-title text-black mt-5">
                {title}
              </h2>
              <p className="intro is-hidden-mobile" >
                  {text}
                </p>
              <div className={`row cards-row is-flex-wrap-wrap is-flex is-justify-content-start py-1 ${showCards ? ' ' : 'cards_hidden'} `}>
              <p className="intro is-hidden-desktop" onClick={() => setShowCards(!showCards)}>
                  {text}
                  {showCards ? (
                    <Image
                      name="minus.svg"
                      className="inline-svg circle-minus is-hidden-desktop arrow inline "
                      alt="shore.com"
                    />
                  ) : (
                    <Image
                      name="circle_plus_white.svg"
                      className="inline-svg circle-plus is-hidden-desktop arrow inline "
                      alt="shore.com"
                    />
                  )}
                </p>
                {cards &&
                  Object.keys(cards).map((k, j) => {
                    const { icon, text } = cards[k];
                    return (
                      <div
                        className={`${j} card col why-card rounded ${
                          !showCards ? 'hidden' : ''
                        }`}
                      >
                        <Image
                          name={icon}
                          alt="angle-left icon"
                          className="inline-icon angle inline  mb-2"
                        />
                        <p>{text}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        );
      })}
 
      <section id="roi_section" className="section background-new-grey">
 
        <div className="container media-box-rtl">
          <div className="columns is-centered is-vcentered">
            <div className="column is-6 left-col is-12-mobile no-pad ">
              <div className="innerwrap">
                <h2 className="title title-large section-title text-black">
                  {roi.title}
                </h2>
                <p
                  className="text-medium"
                  dangerouslySetInnerHTML={{ __html: roi.text }}
                />
                <Button
                  to={
                    getPageUrl(roi.cta_key, 'de')
                      ? getPageUrl(roi.cta_key, 'de').url
                      : ''
                  }
                  className="mt-5 new-button"
                >
                  {roi.cta_text}
                </Button>
              </div>
            </div>
 
            <div className="column is-6 is-12-mobile has-text-centered px-0"  data-aos="fade-up">
              <Animation name={roi.animation} className="roi-animation"  />
 
            </div>
          </div>
        </div>
      </section>
      <ComparisonSection data={comparison} />
 
      <section id="collection_section" className="section" >
         <div className="container">
          <div className="columns is-centered is-vcentered text-row">
            <div className="column is-7 is-12-mobile has-text-centered no-pad">
              {collection.title && (
                <h2 className="title title-large section-title text-black mb-5">
                  {collection.title}
                </h2>
              )}
            </div>
          </div>
        </div>

        <KollektionSlider slides={collection.cards} />
        <div className="container"></div>
      </section>

 
      <section id="start_section" className="section background-new-grey">
         <div className="container media-box-rtl">
          <div className="columns is-centered is-vcentered">
            <div className="column is-6 left-col is-12-mobile no-pad ">
              <div className="innerwrap">
                <h2 className="title title-large section-title text-black ">
                  {start.title}
                </h2>
                <p className="text-medium">{start.text}</p>
                <Button
                  to={
                    getPageUrl(start.cta_key, 'de')
                      ? getPageUrl(start.cta_key, 'de').url
                      : ''
                  }
                  className="mt-5 new-button"
                >
                  {start.cta_text}
                </Button>
              </div>
            </div>
 
            <div className="column is-6 is-12-mobile has-text-centered px-0"  data-aos="fade-up">
               <ImageGallery
                name={start.image}
                alt=""
                className="section-image"
                maxWidth="1058"
                height="384"
              />
            </div>
          </div>
        </div>
      </section>
      <QuoteSlider data={quote} />
      <ConversionSection ctaUrl={t('conversion.ctaUrl')} />  
    </Layout>
  );
};

export default AboutUsPage;

/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../ui/image';

const ComparisonSection: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  return (
    <section className="comparison_section">
      <div className="sections-title columns is-centered container mx-auto">
        <div className="column is-8 pb-0">
          <h2 className="title title-large mx-auto  text-centered text-black">
            {data.title}
          </h2>
        </div>
      </div>
      <div className="comparison">
        <div className="column is-12 pt-0">
          <table className="mx-auto">
            <thead>
              <tr>
                <th>{data.header_left}</th>
                <th>{data.header_right}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data.reasons).map((key, i) => {
                return (
                  <tr>
                    <td>
                      <Image
                        name="check_black.svg"
                        className="table-svg table-icon checked"
                        alt="shore.com"
                      />
                      <p> {data.reasons[key].left}</p>
                    </td>
                    <td>
                      <Image
                        name="close-x.svg"
                        className="table-svg table-icon  close"
                        alt="shore.com"
                      />
                      <p> {data.reasons[key].right}</p>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

ComparisonSection.defaultProps = {
  classes: ``,
};
export default ComparisonSection;

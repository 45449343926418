/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FC, useRef } from 'react';
import Slider from 'react-slick';
import { isMobileOnly } from 'react-device-detect';

import { ImageGallery } from '../ui/imageGallery';
import { Image } from '../ui/image';

import Button from '../ui/button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className} custom_slick_arrow  is-hidden-mobile  is-visible-desktop`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_green_outline"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`custom_slick_arrow ${className} is-hidden-mobile  is-visible-desktop`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_green_outline"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}

interface Slide {
  image: string;
  text: string;
  author: string;
  link?: string;
}

const FullWidthSlider: FC<{
  slides: Slide[];
}> = ({ slides }) => {
  const slider = useRef<any>(null);
  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    variableWidth: false,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  function previous(): void {
    if (slider.current) {
      slider.current.slickNext();
    }
  }

  function next(): void {
    slider.current.slickPrev();
  }

  return (
    <>
      <Slider
        ref={(c) => (slider.current = c)}
        {...settings}
        className={` main-slider QuoteSlider text-centered `}
      >
        {Object.keys(slides).map((key) => {
          return (
            <div key={key} className="container">
              <div className="columns">
                <div className="is-5 column">
                  {!slides[key].cvideo && (
                    <ImageGallery
                      name={slides[key].image}
                      width={360}
                      height={450}
                      alt="slider image"
                      className="quote_slider_image"
                      key={key}
                    />
                  )}
                  {slides[key].cvideo && (
                    <div
                      className="quote_slider_video_wrap"
                      style={{
                        backgroundImage: `url(https://res.cloudinary.com/shore-gmbh/image/upload//c_limit,h_500,w_400/v1713173433/other/home/_testimonials/${slides[key].cvideo}_poster)`,
                      }}
                    >
                      <iframe
                        src={`https://player.cloudinary.com/embed/?public_id=v1704733824/other/home/_testimonials/${slides[key].cvideo}.mp4&cloud_name=shore-gmbh&player[controls]=false&player[muted]=true&player[width]=360&player[height]=450&player[hideContextMenu]=false&player[autoplayMode]=on-scroll&player[autoplay]=true&player[loop]=true`}
                        width="360"
                        height="450"
                        title="image video"
                        className="quote_slider_image"
                        allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                        frameBorder="0"
                      />
                    </div>
                  )}
                </div>
                <div className="is-7 column">
                  <div className="inner_wrap">
                    <h3 className="text-white title title-slider">
                      "{slides[key].text}"
                    </h3>
                    <p className="text-author text-white">
                      {slides[key].author}
                    </p>
                    {slides[key].link && (
                      <Button color="exclusive" link to={slides[key].link}>
                        {slides[key].linkText}
                        <Image
                          name="play-video.svg"
                          alt="play-video icon"
                          className="inline-icon play-video play-icon inline"
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="slider_nav is-visible-mobile is-hidden-desktop">
        <div className=" custom_slick_arrow  " onClick={previous}>
          <ImageGallery
            name="chevron_left_circle_green_outline"
            className="arrow arrow-left"
            alt="arrow left"
            width={46}
            height={46}
          />
        </div>
        <div className=" custom_slick_arrow " onClick={next}>
          <ImageGallery
            name="chevron_right_circle_green_outline"
            className="arrow arrow-left"
            alt="arrow left"
          />
        </div>
      </div>
    </>
  );
};

const QuoteSlider: FC<{
  data: any;
}> = ({ data }) => {
  const slides = data.quoteList;
  return (
    <section
      // eslint-disable-next-line prettier/prettier
      className={`${
        data.classes ? data.classes : ''
      } section quote-section section-quote new-quote-section has-background-pure-black `}
    >
      <FullWidthSlider slides={slides} />
    </section>
  );
};

export default QuoteSlider;
